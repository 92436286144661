import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';

const AdminTable = () => {
  const [admins, setAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const [lastFiftyGames, setLastFiftyGames] = useState([]);
  const [showLastFiftyGames, setShowLastFiftyGames] = useState(false);
  const [lastTenGamesUsername, setLastTenGamesUsername] = useState('');
  const [showLast30DaysProfits, setShowLast30DaysProfits] = useState(false);
  const [last30DaysProfits, setLast30DaysProfits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');

  const API = process.env.REACT_APP_API;

  useEffect(() => {
    const checkAuthorization = () => {
      const expirationTime = localStorage.getItem('authExpiration');
      if (expirationTime && new Date().getTime() < parseInt(expirationTime)) {
        setAuthorized(true);
        fetchAdmins();
      } else {
        localStorage.removeItem('authExpiration');
        setAuthorized(false);
        setShowPasswordPrompt(true);
      }
    };

    checkAuthorization();
    const interval = setInterval(checkAuthorization, 60 * 1000); // Check every minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === '2256') {
      const expirationTime = new Date().getTime() + 5 * 60 * 1000; // 5 minutes from now
      localStorage.setItem('authExpiration', expirationTime);
      setAuthorized(true);
      setShowPasswordPrompt(false);
      fetchAdmins();
    } else {
      alert('Invalid password');
    }
  };


  const handleRefresh = () => {
    setIsLoading(true);
    fetchAdmins().finally(() => {
      setIsLoading(false);
    });
  };

  const LoadingOverlay = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <Spinner animation="border" variant="light" />
      </div>
    );
  };
  const [lastTenGames, setLastTenGames] = useState([]);

  const fetchLast30DaysProfits = () => {
    setIsLoading(true);
    const today = new Date();
    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);

    const last30DaysProfits = [];

    for (let date = last30Days; date <= today; date.setDate(date.getDate() + 1)) {
      const dateString = date.toISOString().slice(0, 10);
      const dailyProfit = admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((total, admin) => {
        const dailyStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(dateString));
        return total + (dailyStat ? dailyStat.profit : 0);
      }, 0);
      last30DaysProfits.push({ date: dateString, profit: Math.round(dailyProfit * 0.2) });
    }

    setLast30DaysProfits(last30DaysProfits.reverse());
    setShowLast30DaysProfits(true);
    setIsLoading(false)
  };

  const fetchLastTenGames = async (adminId, adminUsername) => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API}/admin/${adminId}/last-games`);
      setLastTenGames(response.data);
      setLastTenGamesUsername(adminUsername);
    } catch (error) {
      console.error('Error fetching last 10 games:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const fetchLastFiftyGames = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API}/admin/last-games`);
      setLastFiftyGames(response.data);
      setShowLastFiftyGames(true);
    } catch (error) {
      console.error('Error fetching last 50 games:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true)
      await axios.post(`${API}/admin/signup/protected/naan`, { username, password, initialWallet });
      alert('User registered successfully');
      setShowRegistration(false);
      setUsername('');
      setPassword('');
      setInitialWallet('');
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Failed to register user');
    } finally {
      setIsLoading(false)
    }
  };

  const getYesterdaysProfit = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toISOString().slice(0, 10);

    const yesterdayStats = admins
      .filter((admin) => admin.username !== '0923125171')
      .flatMap((admin) =>
        admin.statsByPeriod.filter((stat) => stat.date.startsWith(yesterdayString))
      );

    const totalProfit = yesterdayStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit * 0.2;
  };


  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${API}/admin/naan/all/protected`);
      setAdmins(response.data);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleEditWallet = (adminId) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const handleAddAmountChange = (event) => {
    setAddAmount(event.target.value);
  };

  const addToWallet = async (adminId) => {
    try {
      const currentAdmin = admins.find((admin) => admin._id === adminId);
      const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
      await axios.put(`${API}/admin/${adminId}/wallet`, { wallet: updatedWallet });
      setAdmins(
        admins.map((admin) => (admin._id === adminId ? { ...admin, wallet: updatedWallet } : admin))
      );
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    }
  };


  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().slice(0, 10);
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };

  const getThisWeekAverage = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    const averageProfit = totalProfit / thisWeekStats.length;

    return isNaN(averageProfit) ? 0 : averageProfit.toFixed(2);
  };

  const getThisWeekProfit = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };


  const getMaxProfit = (admin) => {
    const maxProfit = Math.max(...admin.statsByPeriod.map((stat) => stat.profit));
    return isFinite(maxProfit) ? maxProfit : 0;
  };

  const getTodaysTotalProfit = () => {
    return admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const getThisWeekTotalProfit = () => {
    return admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((total, admin) => total + getThisWeekProfit(admin), 0);
  };

  const getMonthlyProfit = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthlyStats = admins
      .filter((admin) => admin.username !== '0923125171')
      .flatMap((admin) =>
        admin.statsByPeriod.filter((stat) => {
          const statDate = new Date(stat.date);
          return statDate.getMonth() === currentMonth && statDate.getFullYear() === currentYear;
        })
      );

    const totalProfit = monthlyStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };

  const getTotalProfitToDate = () => {
    const totalProfit = admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((sum, admin) => sum + admin.totalProfit, 0);
    return totalProfit;
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortAdmins = (admins, key, direction) => {
    return admins.sort((a, b) => {
      let valueA, valueB;

      if (key === 'lastLoginTime') {
        valueA = a.lastLoginTime ? new Date(a.lastLoginTime) : 0;
        valueB = b.lastLoginTime ? new Date(b.lastLoginTime) : 0;
      } else if (key === 'todaysProfit') {
        valueA = getTodaysProfit(a);
        valueB = getTodaysProfit(b);
      } else if (key === 'thisWeekAverage') {
        valueA = parseFloat(getThisWeekAverage(a));
        valueB = parseFloat(getThisWeekAverage(b));
      } else if (key === 'maxProfit') {
        valueA = getMaxProfit(a);
        valueB = getMaxProfit(b);
      } else {
        valueA = a[key];
        valueB = b[key];
      }

      if (valueA < valueB) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedAdmins = sortConfig.key
    ? sortAdmins(admins, sortConfig.key, sortConfig.direction)
    : admins;

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getArrowDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };



  return (
    <div className="container">
      {isLoading && <LoadingOverlay />}
      {showPasswordPrompt && (
        <Modal show={showPasswordPrompt} onHide={() => setShowPasswordPrompt(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Password Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handlePasswordSubmit}>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={enteredPassword}
                  onChange={(e) => setEnteredPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {authorized && (
        <div>
        <div className='d-flex'>
          <h2 className="my-2">Admin Dashboard</h2>
          <Button variant="primary" onClick={handleRefresh} className="my-4 mx-2" disabled={isLoading}>
            {isLoading ? 'Refreshing...' : 'Refresh Data'}
          </Button>
          <Button variant="primary" onClick={() => setShowRegistration(true)} className="my-4" style={{maxHeight: '65px', marginLeft: '60%' }}>
            Register User
          </Button>
          <Button variant="dark" onClick={fetchLastFiftyGames} className="my-4">
            Last 50 Games
          </Button>
          <Button variant="dark" onClick={fetchLast30DaysProfits} className="my-4 mx-2">
            Last 30 Days Profits
          </Button>
        </div>

        <Modal show={showLast30DaysProfits} onHide={() => setShowLast30DaysProfits(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Profits of Last 30 Days</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {last30DaysProfits.map((entry) => (
                  <tr key={entry.date}>
                    <td>{entry.date}</td>
                    <td>{entry.profit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>

        <Modal show={showLastFiftyGames} onHide={() => setShowLastFiftyGames(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Last 50 Games</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Admin</th>
                  <th>Betting Amount</th>
                  <th>Number of Players</th>
                  <th>Winning Amount</th>
                  <th>Profit</th>
                  <th> Status</th>
                </tr>
              </thead>
              <tbody>
                {lastFiftyGames.map((game) => (
                  <tr key={game._id}>
                    <td>{new Date(game.createdAt).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })} {new Date(game.createdAt).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit'
                    })}</td>
                    <td>{game.admin.username}</td>
                    <td>{game.bettingAmount}</td>
                    <td>{game.numberOfPlayers}</td>
                    <td>{game.payoutToWinner}</td>
                    <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                    <td>{game.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>

        <Modal show={showRegistration} onHide={() => setShowRegistration(false)}>
          <Modal.Header closeButton>
            <Modal.Title>User Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleRegistration}>
              <Form.Group controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              </Form.Group>
              <Form.Group controlId="initialWallet">
                <Form.Label>Initial Wallet</Form.Label>
                <Form.Control type="number" value={initialWallet} onChange={(e) => setInitialWallet(e.target.value)} required />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Register
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
        <div className='m-2'>
          <button className="btn btn-success">Today's Profit: {getTodaysTotalProfit() * 0.2}</button>
          <button className="btn btn-success mx-2">Yesterday's Profit: {Math.round(getYesterdaysProfit())}</button>
          <button className="btn btn-success mx-2">This Week's Total Profit: {Math.round(getThisWeekTotalProfit() * 0.2)}</button>
          <button className="btn btn-success">This month's Profit: {Math.round(getMonthlyProfit() * 0.2)}</button>
          <button className="btn btn-success mx-2">Total Profit to Date: {Math.round(getTotalProfitToDate() * 0.2)}</button>
        </div>
        <div className="m-2 d-flex">
          <button className="btn btn-dark">Today's Total Revenue: {Math.round(getTodaysTotalProfit())}</button>
          <button className="btn btn-dark mx-2">This Week's Total Revenue: {Math.round(getThisWeekTotalProfit())}</button>
          <button className="btn btn-dark">Monthly Revenue: {Math.round(getMonthlyProfit())}</button>
          <button className="btn btn-dark mx-2">Total Revenue to Date: {Math.round(getTotalProfitToDate())}</button>
        </div>

        <table className="table table-striped table-hover">
        <thead>
            <tr>
              <th onClick={() => requestSort('username')}>
                Username {getArrowDirection('username')}
              </th>
              <th onClick={() => requestSort('lastLoginTime')}>
                Last Login {getArrowDirection('lastLoginTime')}
              </th>
              <th onClick={() => requestSort('totalProfit')}>
                Total Profit {getArrowDirection('totalProfit')}
              </th>
              <th onClick={() => requestSort('wallet')}>
                Wallet {getArrowDirection('wallet')}
              </th>
              <th>Stats by Period</th>
              <th onClick={() => requestSort('todaysProfit')}>
                Today's Profit {getArrowDirection('todaysProfit')}
              </th>
              <th onClick={() => requestSort('thisWeekAverage')}>
                This Week's Average {getArrowDirection('thisWeekAverage')}
              </th>
              <th onClick={() => requestSort('maxProfit')}>
                Max Profit to Date {getArrowDirection('maxProfit')}
              </th>
              <th>Last Games</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin._id}>
                <td>{admin.username}</td>
                <td>
                  {admin.lastLoginTime ? (
                    <>
                      {new Date(admin.lastLoginTime).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}{' '}
                      {new Date(admin.lastLoginTime).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })}
                    </>
                  ) : (
                    ''
                  )}
                </td>

                <td>{Math.round(admin.totalProfit)}</td>
                <td>{admin.wallet}</td>
                <td>
                  <details>
                    <summary className="btn btn-sm btn-info">View Stats</summary>
                    <table className="table table-bordered mt-2">
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Profit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admin.statsByPeriod.map((stat, index) => (
                          <tr key={index}>
                            <td>{new Date(stat.date).toLocaleDateString()}</td>
                            <td>{stat.profit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </details>
                </td>
                <td>{getTodaysProfit(admin)}</td>
                <td>{Math.round(getThisWeekAverage(admin))}</td>
                <td>{Math.round(getMaxProfit(admin))}</td>
                <td>
                  {editingAdminId === admin._id ? (
                    <div className="input-group mt-1">
                      <input
                        type="number"
                        value={addAmount}
                        onChange={handleAddAmountChange}
                        className="form-control"
                        placeholder="Add amount"
                      />
                      <button onClick={() => addToWallet(admin._id)} className="btn btn-primary">
                        Add
                      </button>
                      <button onClick={() => setEditingAdminId(null)} className="btn btn-secondary">
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button onClick={() => handleEditWallet(admin._id)} className="btn btn-warning btn-sm">
                      Edit
                    </button>
                  )}
                </td>
                <td>
                  <button
                    onClick={() => fetchLastTenGames(admin._id, admin.username)}
                    className="btn btn-info btn-sm"
                  >
                    Last 10 Games
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          show={lastTenGames.length > 0}
          onHide={() => setLastTenGames([])}
          username={lastTenGames.length > 0 ? lastTenGames[0].admin.username : ''}
        >
          <Modal.Header closeButton>
            <Modal.Title>Last 10 Games - {lastTenGamesUsername}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Betting Amount</th>
                  <th>Number of Players</th>
                  <th>Winning Amount</th>
                  <th>Profit</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {lastTenGames.map((game) => (
                  <tr key={game._id}>
                    <td>
                      {new Date(game.createdAt).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}{' '}
                      {new Date(game.createdAt).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })}
                    </td>
                    <td>{game.bettingAmount}</td>
                    <td>{game.numberOfPlayers}</td>
                    <td>{game.payoutToWinner}</td>
                    <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                    <td>{game.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
        </div>
      )}
    </div>
  );
};

export default AdminTable;
