import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './GamesPage.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BingoCard from './BingoCard';
import winSound from '../Assets/win.mp3';
import loseSound from '../Assets/lose.mp3';
import LoadingOverlay from './LoadingOverlay';
import localforage from 'localforage';

const GamePage = () => {
  const location = useLocation();
  const { audioLanguage } = location.state || {};
  const gameId = location.state?.gameId;
  const calledNumbers = location.state?.calledNumbers;
  const playingCards = location.state?.playingCards;
  const callSpeed = location.state?.callSpeed;
  const payoutAmount = location.state?.payoutToWinner
  const [totalCalls, setTotalCalls] = useState(0);
  const [checkedNumber, setCheckedNumber] = useState('');
  const [lastCalledNumber, setLastCalledNumber] = useState('');
  const [intervalId, setIntervalId] = useState(null);
  const [winnerCard, setWinnerCard] = useState(null);
  const [isPaused, setIsPaused] = useState(true);
  const audioRef = useRef(null);
  const [showWinnerModal, setShowWinnerModal] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [slideshowTables, setSlideshowTables] = useState([]);
  const [countdown, setCountdown] = useState(callSpeed / 1000);
  const [remainingCountdown, setRemainingCountdown] = useState(callSpeed / 1000);
  const [showCardDisplay, setShowCardDisplay] = useState(false);
  const [winner, setWinner] = useState(false);
  const [calledIndex, setCalledIndex] = useState(-1);
  const [calledNumbersArray, setCalledNumbersArray] = useState([]);
  const winAudioRef = useRef(null);
  const loseAudioRef = useRef(null);
  const API = process.env.REACT_APP_API;
  const [audioFilesLoaded, setAudioFilesLoaded] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  useEffect(() => {
    const fetchAndStoreAudioFiles = async () => {
      for (let i = 1; i <= 75; i++) {
        let letter = '';
        if (i >= 1 && i <= 15) {
          letter = 'B';
        } else if (i >= 16 && i <= 30) {
          letter = 'I';
        } else if (i >= 31 && i <= 45) {
          letter = 'N';
        } else if (i >= 46 && i <= 60) {
          letter = 'G';
        } else if (i >= 61 && i <= 75) {
          letter = 'O';
        }
        const number = letter + i;
        const audioKey = `audio-${audioLanguage}-${number}`;
        const storedAudioFile = await localforage.getItem(audioKey);
        if (!storedAudioFile) {
          const audioSrc = require(`../Assets/Audio/${audioLanguage}/${number}.aac`);
          const response = await fetch(audioSrc);
          const arrayBuffer = await response.arrayBuffer();
          localforage.setItem(audioKey, arrayBuffer);     
        }
      }
      setAudioFilesLoaded(true);
    };
  
    fetchAndStoreAudioFiles();
  }, [audioLanguage]);

  useEffect(() => {
    let interval;
    let countdownInterval;
  
    const startCallingNumbers = () => {
      interval = setInterval(() => {
        if (!isPaused && audioFilesLoaded) {
          setCalledIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex < calledNumbers.length) {
              const newLastCalledNumber = calledNumbers[newIndex];
              setLastCalledNumber(newLastCalledNumber);
              setCalledNumbersArray(calledNumbers.slice(0, newIndex + 1));
              
              localforage.getItem(`audio-${audioLanguage}-${newLastCalledNumber}`).then((arrayBuffer) => {
                const audioBlob = new Blob([arrayBuffer], { type: 'audio/aac' });
                const audioUrl = URL.createObjectURL(audioBlob);
                audioRef.current.src = audioUrl;
                audioRef.current.load();
                audioRef.current.addEventListener('canplaythrough', () => {
                  audioRef.current.play();
                });
              });
  
              return newIndex;
            }
            return prevIndex;
          });
        }
      }, callSpeed);
    };
  
    const startCountdown = () => {
      let remainingTime = Math.floor(callSpeed / 1000);

      setCountdown(remainingTime);
  
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          if (newCountdown <= 0) {
            clearInterval(countdownInterval);
          }
          return newCountdown;
        });
      }, 800);
    };
  
    clearInterval(interval);
    clearInterval(countdownInterval);
  
    if (!isPaused && calledIndex < calledNumbers.length - 1) {
      startCallingNumbers();
      startCountdown();
    }
  
    return () => {
      clearInterval(interval);
      clearInterval(countdownInterval);
    };
  }, [callSpeed, calledIndex, audioLanguage, calledNumbers, isPaused]);
  

  const handleCheckNumber = async () => {
    try {
      setIsChecking(true);
      if (!playingCards.includes(checkedNumber)) {
        alert('Card is not in the list of players. Please check the card number and try again.')
        setIsChecking(false)
        return;
      }
      const response = await axios.post(`${API}/game/isWinner/${gameId}/${checkedNumber}`, {
        calledNumbers: calledNumbersArray
      });

      const { isWinner } = response.data;

      if (isWinner) {
        setWinner(true);
        setShowCardDisplay(true);
        setShowWinnerModal(true);
        winAudioRef.current.play();

      } else{
        setWinner(false);
        setShowCardDisplay(true);
        setShowWinnerModal(true);
        loseAudioRef.current.play();
      }
    } catch (error) {
      console.error('Error checking winner:', error);
      alert('Error occurred while checking the winner card.');
    } finally{
      setIsChecking(false)
    }
  };

  const generateRows = (letter, start) => {
    return (
      <div className="row p-0" style={{maxHeight: 60}}>
        <div className="col bg-dark text-danger text-center m-1 pb-1 px-0" style={{fontSize: 38, maxHeight: 48, maxWidth: 45}}>{letter}</div>
        {[...Array(15)].map((_, i) => {
          const number = start + i;
          const isCalled = calledNumbersArray.includes(letter + number);
          return (
            <div key={number} style={{fontSize: 38, maxHeight: 35}} className={`col m-1 p-0 ${isCalled ? 'called' : ''}`}>
              {number}
            </div>
          );
        })}
      </div>
    );
  };

  const pauseGame = async () => {
    setIsPaused(true);
  }
  
  const resumeGame = async () => {
    setIsPaused(false);
  }


  const endGame = async () => {
    try {  
      const response = await axios.post(
        `${API}/game/${gameId}/end`,
        null,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error ending the game:', error);
    }
  };
  
  const winningPatterns = [
      [1, 0, 0, 0, 0, 6, 0, 0, 0, 0, 10, 0, 12, 0, 0, 15, 0, 0, 0, 0, 21, 0, 0, 0, 0],
      [0, 2, 0, 0, 0, 0, 7, 0, 0, 0, 0, 11, 12, 0, 0, 0, 17, 0, 0, 0, 0, 22, 0, 0, 0],
      [0, 0, 3, 0, 0, 0, 0, 8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 18, 0, 0, 0, 0, 23, 0, 0],
      [0, 0, 0, 4, 0, 0, 0, 0, 9, 0, 0, 0, 12, 13, 0, 0, 0, 0, 19, 0, 0, 0, 0, 24, 0],
      [0, 0, 0, 0, 5, 0, 0, 0, 0, 10, 0, 0, 12, 0, 14, 0, 0, 0, 0, 20, 0, 0, 0, 0, 25],
      
      [1, 2, 3, 4, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 6, 7, 8, 9, 10, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 13, 12, 14, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 16, 17, 18, 19, 20, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 22, 23, 24, 25],
  
      [1, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 19, 0, 0, 0, 0, 0, 25],
      [0, 0, 0, 0, 5, 0, 0, 0, 10, 0, 0, 0, 12, 0, 0, 0, 17, 0, 0, 0, 21, 0, 0, 0, 0],
  
      [1, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 0, 0, 0, 25],
      [0, 0, 0, 0, 0, 0, 7, 0, 9, 0, 0, 0, 12, 0, 0, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0],
    ];

  useEffect(() => {
      const tables = winningPatterns.map((pattern) => generateTable(pattern));
      setSlideshowTables(tables);
    }, []);
    
    
    const generateTable = (pattern) => {
      const cellSize = 25;
      const dotSize = 25;
    
      const cells = pattern.map((value, index) => (
        <td
          style={{
            padding: '15px 19px',
            position: 'relative',
            width: `25px`,
            height: `26px`,
            backgroundColor: '#666',
            border: '1px solid black',
          }}
        >
          <div
            key={index}
            className='text-center mx-auto'
            style={{
              width: `${dotSize}px`,
              height: `${dotSize}px`,
              backgroundColor: value === 12 ? 'yellow' : value > 0 ? '#000' : 'transparent',
              borderRadius: '50%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          ></div>
        </td>
      ));
    
      const rows = [];
      for (let i = 0; i < 5; i++) {
        rows.push(
          <tr key={i} >
            {cells.slice(i * 5, (i + 1) * 5)}
          </tr>
        );
      }
    
      const headerCells = ['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
        <th
          key={index}
          className='fw-bold text-center'
          style={{
            backgroundColor: ['#3B82F6', '#EF4444', '#F97316', '#22C55E', '#ffc900'][index],
            width: `10px`,
            height: `10px`,
            fontSize: '50',
            border: '1px solid white',
          }}
        >
          {letter}
        </th>
      ));
    
      return (
        <table className='mb-0 pb-0' style={{backgroundColor: '#E2E8F0'}}>
          <thead>
            <tr>{headerCells}</tr>
          </thead>
          <tbody className='mb-0 pb-0' style={{backgroundColor: '#E2E8F0'}}>{rows}</tbody>
        </table>
      );
    };
  
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      arrows: false,
    };

  const handleCloseWinnerModal = () => {
        setShowWinnerModal(false);
        setShowCardDisplay(false);
      };

  return (
    <div className="container-fluid p-2" style={{ minHeight: '100vh', backgroundColor: '#111'}}>
      {!audioFilesLoaded && <LoadingOverlay />}
      <audio ref={winAudioRef} src={winSound} />
      <audio ref={loseAudioRef} src={loseSound} />
      <Modal show={showWinnerModal} onHide={handleCloseWinnerModal} size="xl" >
        <Modal.Body className='bg-dark'>
          {showCardDisplay && <BingoCard cardNumber={checkedNumber} calledNumbers={calledNumbersArray} isWinner={winner} />}
        </Modal.Body>
        <Modal.Footer className='bg-dark'>
          <button className="btn btn-secondary" onClick={handleCloseWinnerModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div className="col-2 text-white">
          <h3 className="text-center" style={{fontSize: '1.75rem', fontWeight: 700}}>Calls</h3>
          <h1 className="call_number x-5 mx-4" style={{backgroundColor: '#333', fontSize: '4rem'}}>{calledNumbersArray.length}</h1>
          <div className="card mx-1">
            <div className="card-body p-0">
              <Slider {...settings}>
                {slideshowTables.map((table, index) => (
                  <div key={index}>{table}</div>
                ))}
              </Slider>
            </div>
          </div>
          <div>
            <h5 className="mt-1 mb-0 text-center">Countdown</h5>
            <h1 className="text-center my-0" style={{fontSize: '4rem'}}>{countdown}</h1>
          </div>
          <div className='mx-auto text-center my-2'>
            {
              isPaused ? (
                <button className="btn btn-success text-center fs-5 px-4 py-1 mx-auto text-center" onClick={resumeGame}>Resume</button>  
              ) : (
                <button className="btn btn-secondary text-center" onClick={pauseGame}>Pause</button>
              )
            }
          </div>
          <div className="d-flex flex-row mx-2">
            <input
              type="text"
              value={checkedNumber}
              onChange={(e) => setCheckedNumber(e.target.value)}
              placeholder="Card number"
              style={{backgroundColor: '#475569', marginRight: '5px', width:'50%'}}
            />
            <button className="btn btn-info my-0" onClick={handleCheckNumber}>
              Check
            </button>
          </div>
          <div className='mx-auto text-center'>
            <button className="btn btn-danger text-center mt-3 ml-2 w-50" onClick={endGame}>
              End game
            </button>
          </div>
          
        </div>
        <div className="col-10 mt-1 p-0 text-secondary fs-3 fw-bold" style={{maxHeight: 30}}>
          <div style={{backgroundColor: '#111'}}>
            {generateRows('B', 1)}
            {generateRows('I', 16)}
            {generateRows('N', 31)}
            {generateRows('G', 46)}
            {generateRows('O', 61)}
          </div>
          <div className="" style={{ overflowX: "scroll"}}>
            <div className="payout-amount align-right fs-1 text-white text-start mt-1 mx-5"><font style={{ color:'lightBlue'}} >{payoutAmount} Birr ደራሽ</font></div>
            <div className="last-called-number-container mt-0 " style={{backgroundColor: '#222', overflowX: "scroll"}}>
              {calledNumbersArray.map((number, index) => (
                <div key={index} className={`${number.includes('I') ? 'border-i' : number.includes('N') ? 'border-n' : number.includes('G') ? 'border-g' : 'border-o'} ${number === calledNumbersArray[calledNumbersArray.length - 1] ? 'last-called-number-circle' : 'last-five-numbers'}`} style={{ margin: '5px 5px' }}>
                  {number}
                  
                </div>
              ))}
              <audio ref={audioRef} />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePage;