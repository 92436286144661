import { Container, Row, Col } from 'react-bootstrap';

function Card({ card }) {

  return (
    <Container className="mt-3 p-1 rounded fw-bold fs-3" style={{maxWidth: '400px'}}>
      <h2 className='text-center text-white'>Uno <small className='text-warning'>Bingo</small></h2>

      <Row className='mx-auto'>
        <Col xs={2} className="text-white border p-0 text-center">
          <h3 className='bg-dark text-white mb-0 fs-1'>B</h3> 
          {card.numbers.B.map(num => (
            <div className="text-white border fs-1" style={{backgroundColor: '#FFA500'}}>{num}</div>  
          ))}
        </Col>

        <Col xs={2} className="text-danger border p-0 text-center">
         <h3 className='bg-primary text-white mb-0 fs-1'>I</h3>
         {card.numbers.I.map(num => (
           <div className="text-white border fs-1" style={{backgroundColor: '#FFA500'}}>{num}</div>  
         ))}
        </Col>

        <Col xs={3} className="text-warning border p-0 text-center">
          <h3 className='bg-success text-white mb-0 fs-1'>N</h3>
          {card.numbers.N.map((num,index) => (
            <div className={`border fs-1 ${index === 2 ? 'text-success' : 'text-white'}`} style={{backgroundColor: '#FFA500'}}>{num}</div>
          ))}
        </Col>

        <Col xs={2} className="text-success border p-0 text-center">
          <h3 className='bg-warning text-white mb-0 fs-1'>G</h3>
          {card.numbers.G.map(num => (
            <div className="text-white border fs-1" style={{backgroundColor: '#FFA500'}}>{num}</div>
          ))}
        </Col>

        <Col xs={2} className="text-info border p-0 text-center">
          <h3 className='bg-info text-white mb-0 fs-1'>O</h3>
          {card.numbers.O.map(num => (
            <div className="text-white border fs-1" style={{backgroundColor: '#FFA500'}}>{num}</div>
          ))}
        </Col>
      </Row>
      <h3 className='text-white text-center mt-2'>Card No. {card.paletteNumber}</h3>
    </Container>
  );

}

export default Card;