import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { FaGamepad } from 'react-icons/fa';

function AdminGames() {
  const [games, setGames] = useState([]);
  const API = process.env.REACT_APP_API;

  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0
  });
  
  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` }  
      });
      const data = await response.json();
      setStats(data);
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API}/admin/myGames`, {
          headers: { Authorization: `Bearer ${token}` }  
        });
        
        const data = await response.json();
        setGames(data);

      } catch(error) {
        console.log(error);  
      }
    }

    fetchGames();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const year = date.getFullYear();
    const month = months[date.getMonth()]; 
    const day = date.getDate();
    const dayOfWeek = days[date.getDay()];

    return `${dayOfWeek}, ${month} ${day}, ${year}`; 
  }
  
 
  return (
    <div className="my-3 m-4 p-3 text-info">
      <h2 className=''>Statistics</h2>
      <div className='d-flex'>
        <div className="d-flex align-items-center p-3" style={{minHeight: '75px', backgroundColor: '#228', marginRight: 100, borderRadius: '16px'}}>
          <span className="text-white fs-5 pr-4 text-info">{stats.totalGames} Games</span>
        </div>
        <div className="d-flex align-items-center p-3" style={{minHeight: '75px', backgroundColor: '#228', marginRight: 100, borderRadius: '16px'}}>
          <span className="text-white fs-5">Balance: {stats.wallet} Birr</span>
        </div>
      </div>
      <h4 className='text-white mt-5 text-dark text-center'>Sessions</h4>
      
      <Table bordered hover variant="info">
        <thead>
          <tr>
            <th style={{backgroundColor: 'black'}} className='text-info'>Date</th> 
            <th style={{backgroundColor: 'black'}} className='text-info'>Bet Amount</th>
            <th style={{backgroundColor: 'black'}} className='text-info'>Players</th>
            <th style={{backgroundColor: 'black'}} className='text-info'>Calls</th>
            <th style={{backgroundColor: 'black'}} className='text-info'>Status</th>
            <th style={{backgroundColor: 'black'}} className='text-info'>Total Bet Amount</th>
            <th style={{backgroundColor: 'black'}} className='text-info'>Profit</th>
          </tr>
        </thead>

        <tbody>
          {games.map(game => (
            <tr key={game.id}>  
              <td>{formatDate(game.date)}</td>
              <td>{game.bettingAmount} Birr</td>
              <td>{game.players}</td>
              <td>{game.calls}</td>
              <td>{game.status}</td>
              <td>{game.bettingAmount *  game.players} Birr</td>
              <td>{game.bettingAmount *  game.players * 0.2} Birr</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center">
        <p className="mb-0">
          Showing {games.length} games
        </p>
        
        <button className="btn btn-primary">
          Export CSV
        </button>
      </div>

    </div>
  );
}

export default AdminGames;
