import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function login(username, password) {
  const API = process.env.REACT_APP_API;
  try {
    const response = await fetch(`${API}/admin/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    const data = await response.json();

    if (response.ok) {
      if (data.token) {
        localStorage.setItem('token', data.token);
        return data;
      } else {
        throw new Error('Unauthorized');
      }
    } else {
      throw new Error('Login failed. Please check your internet or credentials.');
    }
  } catch (err) {
    throw err;
  }
}

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isAccountBlocked = username === '0900000506';
  if (isAccountBlocked) {
    toast.error('Account blocked', { autoClose: false });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(username, password);
      navigate('/dashboard');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto text-white  py-5 h-100 bg-dark">
      <Form onSubmit={handleSubmit}>
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex align-items-center justify-content-center h-100">
              <div className="col-md-8 col-lg-7 col-xl-6">
                <img
                  src="https://t4.ftcdn.net/jpg/02/57/84/15/360_F_257841551_ijTBIpkhoazelh7UkA68joRds9lNqokP.jpg"
                  className="img-fluid"
                  alt="Phone image"
                />
              </div>
              <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                <h1 className='text-success text-center'>Uno Bingo</h1>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form1Example13">
                    Username
                  </label>
                  <input
                    type="text"
                    id="form1Example13"
                    className="form-control form-control-lg"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form1Example23">
                    Password
                  </label>
                  <input
                    type="password"
                    id="form1Example23"
                    className="form-control form-control-lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="d-flex justify-content-around align-items-center mb-4">
                  <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={isLoading || isAccountBlocked}>
                  {isLoading ? 'Signing in...' : 'Sign in'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Form>
      <ToastContainer />
    </div>
  );
}

export default Login;
