import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

function AdminStats() {
  const API = process.env.REACT_APP_API;
  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` }  
      });
      const data = await response.json();
      setStats(data); 
    };

    fetchStats();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`; 
  }

  // Combine profits for entries with the same date and round to nearest integer
  const combinedStats = {};
  stats.statsByPeriod.forEach(period => {
    const dateStr = formatDate(period.date);
    if (!combinedStats[dateStr]) {
      combinedStats[dateStr] = 0;
    }
    combinedStats[dateStr] += period.profit;
  });

  // Round profit to nearest integer
  Object.entries(combinedStats).forEach(([date, profit]) => {
    combinedStats[date] = Math.round(profit);
  });

  return (
    <div className='m-4 p-4 text-info'>
      <Table bordered hover variant="dark">
        <thead>
          <tr>
            <th style={{backgroundColor: 'black'}} className='text-info'>Date</th>
            <th style={{backgroundColor: 'black'}} className='text-info'>Profit</th>
          </tr>  
        </thead>
        <tbody>
          {Object.entries(combinedStats).map(([date, profit]) => (
            <tr key={date}>
              <td className='text-info'>{date}</td> 
              <td className='text-info'>{profit} Birr</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default AdminStats;
